<script>
  import { onMount, afterUpdate } from "svelte";
  import { state } from "./state.js";

  export let externalLinkURL;
  export let relativeImgURL;
  let availableHeight;
  let element;

  function calcAvailableHeight() {
    let mobile = window.innerWidth <= 600;
    if ($state == 2 && mobile) {
      availableHeight = 0;
      return;
    }
    // console.log("resize");
    element = document.getElementsByClassName("info-container")[0];
    if (mobile) {
      element = document.getElementsByClassName("mobile-above-feature")[0];
    }
    availableHeight =
      window.innerHeight -
      (element.getBoundingClientRect().top +
        window.scrollY +
        element.getBoundingClientRect().height);
  }

  onMount(() => {
    calcAvailableHeight();
  });
  afterUpdate(() => {
    calcAvailableHeight();
  });
</script>

<style lang="scss">
  .feature-container {
    position: fixed;
    width: 400px;
    bottom: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    // mix-blend-mode: exclusion;
    display: grid;
    place-items: center;
  }
  .img {
    cursor: pointer;
    width: 100px;
    height: 80%;
    // outline: red thin solid;
    transition: all 1s ease-in-out;
    transform: translateY(0);
    border-radius: 150px;
    min-height: 50px;
    background-repeat: no-repeat !important;
    background-position: top center !important;
    background-size: cover !important;
    filter: drop-shadow(4px 4px 16px #ffffff);
    // position: fixed;
    // bottom: 0;
    // left: 0;
    //background: url(attr(data-content));
    &:hover {
      transform: translateY(-10px);
      border-radius: 3px;
    }
  }

  @media (max-width: 600px) {
    .feature-container {
      box-sizing: border-box;
      width: 100vw;
    }
  }
  @media (max-width: 350px) {
    .if-small-mobile-hide-feature {
      display: none;
    }
  }
</style>

<svelte:window on:resize={calcAvailableHeight} />
<div
  class="feature-container {$state === 0 ? 'if-small-mobile-hide-feature' : ''}"
  style={`height:${availableHeight}px;`}>
  <div
    class="img"
    on:click={() => {
      window.open(externalLinkURL);
    }}
    style={`background:url("${relativeImgURL}")`} />
</div>
