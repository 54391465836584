<script>
  import Chat from "./Chat.svelte";
  import { state, seed } from "./state.js";
  import ScrollerContent from "./Scroller/ScrollerContent.svelte";
  import { beforeUpdate } from "svelte";
  import { cubicIn } from "svelte/easing";

  import Magic from "./Magic.svelte";

  import Scroller from "./Scroller/Scroller.svelte";
  import AudioPreloader from "./AudioPreloader.svelte";
  import Feature from "./Feature.svelte";

  // Generate an array of booleans to show/hide (magic) buttons
  const animateTotal = 80; // Only animate above the fold
  const total = 1000;
  const delay = 10;
  let initArray = [];

  // Each time this runs there will be fewer kept hidden:
  function buildArray(original, keepHidden) {
    // Initial array:
    // 0: true, 1 to animateTotal: false, animateTotal to total: true
    if (!original.length) {
      return [...Array(total)].map((x, i) => {
        if (i === 0 || i > animateTotal) {
          return true;
        }
        return false;
      });
    } else {
      // Get an array of the indexes that are still hidden
      const leftHidden = original
        .slice(0, animateTotal + 1)
        .map((i, index) => (i ? false : index))
        .filter((i) => i);
      // Calculate how many to reveal this tick:
      const reveal = leftHidden.length - keepHidden;
      // Reveal that many randomly and remove from keptHidden to not remove twice
      for (var i = 0; i < reveal; i++) {
        const leftHiddenIndex = Math.floor(Math.random() * leftHidden.length);
        const originalIndex = leftHidden[leftHiddenIndex];
        leftHidden.splice(leftHiddenIndex, 1);
        original[originalIndex] = true;
      }
      return original.slice(0);
    }
  }

  // Transition in for state 1
  function populateMagicArray() {
    return {
      duration: animateTotal * delay,
      easing: cubicIn,
      tick: (t) => {
        const i = ~~(animateTotal * t);
        const keepHidden = animateTotal - i;
        initArray = buildArray(initArray, keepHidden);
      },
    };
  }

  let no = false;
  let yes = false;
  let backAnim = false;
  let audio;
  let div;
  let autoscroll;

  beforeUpdate(() => {
    autoscroll = div;
    if (autoscroll) div.scrollTop = 0;
  });

  var music = new Audio();
  function playMusic(file) {
    music.pause();
    music = new Audio(file);
    music.play();
  }
</script>

<main bind:this={div} class:no class:yes>
  <AudioPreloader />

  <Scroller>
    <ScrollerContent />
  </Scroller>

  <Feature
    externalLinkURL="https://magic.pointnever.com/"
    relativeImgURL="./cover.jpg"
  />

  {#if $state === 1}
    <svg
      class:no
      class="back"
      on:click={() => {
        state.set(0);
        no = false;
        yes = false;
        initArray = [];
      }}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.5C0 10.0897 2.91031 13 6.5 13C10.0897 13 13 10.0897 13 6.5C13 2.91031 10.0897 0 6.5 0C2.91031 0 0 2.91031 0 6.5ZM6.64531 3.645C6.69194 3.69125 6.729 3.74622 6.75437 3.80679C6.77975 3.86736 6.79295 3.93234 6.79321 3.99801C6.79347 4.06368 6.78079 4.12876 6.7559 4.18953C6.731 4.2503 6.69438 4.30557 6.64812 4.35219L5.01312 6H9.1875C9.32011 6 9.44729 6.05268 9.54105 6.14645C9.63482 6.24021 9.6875 6.36739 9.6875 6.5C9.6875 6.63261 9.63482 6.75979 9.54105 6.85355C9.44729 6.94732 9.32011 7 9.1875 7H5.01312L6.64812 8.64781C6.69437 8.69447 6.73098 8.74979 6.75585 8.81059C6.78073 8.8714 6.79338 8.93651 6.79309 9.00221C6.7928 9.06791 6.77957 9.13291 6.75416 9.19349C6.72875 9.25408 6.69166 9.30906 6.645 9.35531C6.59834 9.40156 6.54303 9.43817 6.48222 9.46304C6.42141 9.48792 6.3563 9.50057 6.2906 9.50028C6.22491 9.49999 6.15991 9.48676 6.09932 9.46135C6.03874 9.43594 5.98375 9.39885 5.9375 9.35219L3.45656 6.85219C3.36363 6.75853 3.31148 6.63194 3.31148 6.5C3.31148 6.36806 3.36363 6.24147 3.45656 6.14781L5.9375 3.64781C5.98376 3.60109 6.03877 3.56395 6.0994 3.53852C6.16003 3.51309 6.22508 3.49987 6.29082 3.4996C6.35657 3.49934 6.42172 3.51205 6.48255 3.537C6.54338 3.56195 6.59869 3.59865 6.64531 3.645Z"
        fill="black"
      />
    </svg>
    <div class="magic-container mobile-above-feature" in:populateMagicArray>
      {#each initArray as s, i}
        <Magic index={i} show={s} {no} />
      {/each}
    </div>
  {:else if $state === 0}
    <div class="chat-container have-you-changed-container mobile-above-feature">
      REST IN POWER SOPHIE
      <br />
      <div
        class="btn"
        on:click={() => {
          playMusic("./audio/Y.mp3");
          state.set(2);
          yes = true;
        }}
      >
        🕊
      </div>
      <!-- <div
        class="btn"
        on:click={() => {
          playMusic('./audio/N.mp3');

          no = true;
          state.set(1);
        }}>
        no
      </div> -->
    </div>
  {:else}
    <Chat seed={$seed} {no} />
  {/if}
</main>

<style lang="scss">
  @keyframes loop {
    0% {
      filter: hue-rotate(0deg) saturate(8);
    }
    50% {
      filter: hue-rotate(360deg) saturate(1);
    }
    100% {
      filter: hue-rotate(0deg) saturate(8);
    }
  }
  main {
    cursor: ne-resize !important;
    position: absolute;

    min-width: 100vw;
    width: 100vw;
    min-height: 100vh;
    overflow: auto;
    // filter: grayscale(0) contrast(1);
    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../bg.jpg) no-repeat center center fixed;
      background-size: cover;
      filter: grayscale(0) contrast(1) saturate(1);
      animation-name: loop;
      animation-duration: 10s;

      animation-iteration-count: infinite;
      transition-property: -moz-filter, -ms-filter, -o-filter, -webkit-filter,
        filter;
      transition-duration: 1s;
    }
    &.no::before {
      filter: grayscale(1) contrast(20);
    }
    &.yes::before {
      // animation: splash 3s normal forwards ease-in-out;
    }
  }

  .magic-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 170px);
    grid-column-gap: 12px;
    grid-row-gap: 14px;
    justify-content: end;

    position: absolute;
    right: 0;
    width: 55%;
    margin-left: 8px;
    padding-top: 8px;
    cursor: ns-resize;

    mix-blend-mode: hard-light;
  }
  .btn {
    display: inline-block;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0;
  }
  @media (max-width: 850px) {
    .magic-container {
      width: 50%;
    }

    .back {
      top: 10px !important;
      right: 50% !important;
    }
    .btn {
      font-size: 24px;
      padding-bottom: 0;
      border-width: 2px;
    }
  }

  @media (max-width: 600px) {
    .have-you-changed-container {
      box-sizing: border-box;
      top: 19.5rem;
      left: 0;
      width: 100%;
      font-size: 40px;
      letter-spacing: -5px;
      line-height: 60px;
      padding: 0 20px;
    }
    .magic-container {
      top: 19.5rem;
      left: 10px;
      display: flex;
      min-height: 53px;
    }

    .back {
      position: fixed;
      top: 5px;
      left: 0;
      z-index: 100;
      width: 100vw;
      text-align: center;
    }

    main {
      position: fixed;
      top: 0;
      bottom: 0;
      &::before {
        background: url(../bg.jpg) no-repeat 25% center fixed;
        background-size: cover;
      }
    }
  }

  @keyframes splash {
    from {
      filter: saturate(1);
    }
    10% {
      filter: saturate(5);
    }
    to {
      filter: saturate(1);
    }
  }

  .back {
    font-size: 25px;
    width: 50px;
    height: 50px;
    position: fixed;
    top: 75px;
    right: 55%;
    text-decoration: underline;
    cursor: w-resize;
    mix-blend-mode: overlay;
    & path {
      fill: #333;
    }
    &:hover path {
      fill: #000;
    }
    &.no {
      mix-blend-mode: difference;
      & path {
        fill: #fff;
      }
      &:hover path {
        fill: #ddd;
      }
    }
  }
</style>
