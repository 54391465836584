<script>
  //------ Intentionaly blank!
  let defaultSpacer = `





`;
  //------- Intentionaly blank!
</script>

<pre>
{defaultSpacer}
AVAILABLE NOW
ON <i>WARP RECORDS</i>


<img
    src="./hr.png" /><br />


<a
    href="https://magic.pointnever.com/follow">FOLLOW</a>





<img
    src="./hr.png" /><br />


    
<a
    href="https://magic.pointnever.com/">ORDER</a>

<!-- <a
    href="https://magic.pointnever.com/lost-but-never-alone">WATCH 'LOST BUT NEVER ALONE'</a>

<a
    href="https://magic.pointnever.com/fallon">WATCH 'I DON'T LOVE ME ANYMORE' (LIVE)</a> -->

<a
    href="https://opn.ffm.to/merch">MERCH</a>
<!-- DO NOT REMOVE THIS COMMENT -->
{defaultSpacer}
</pre>

<style lang="scss">
  img {
    width: 60%;
  }

  pre {
    margin: 0;
    font-family: "Epilogue", sans-serif;
  }
</style>
