<script>
  import { fly } from "svelte/transition";

  import { state } from "./state.js";
  export let seed;
  export let no;
  let numberOfText = 25;
  let promise = getText();

  let wordsVisible = 1;
  let wordsToAnimate;
  const delay = 205;

  async function getText() {
    seed = 99;

    const res = await fetch(`./gpt-2/${(no ? "n" : "y") + seed}.json`, {
      cache: "force-cache",
    });

    const text = await res.text();

    if (res.ok) {
      const textArray = text.split(" ");
      wordsToAnimate = textArray.length;
      return textArray;
    } else {
      return "404lol";
    }
  }
  let container;
  // onMount(() => {
  //   console.log(container.scrollTop);
  // });

  function addWords() {
    return {
      duration: delay * wordsToAnimate,
      easing: (i) => i,
      tick: (t) => {
        wordsVisible = ~~(wordsToAnimate * t);
      },
    };
  }
</script>

<svg
  class:no
  class="back"
  on:click={() => {
    state.set(1);
  }}
  width="13"
  height="13"
  viewBox="0 0 13 13"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M0 6.5C0 10.0897 2.91031 13 6.5 13C10.0897 13 13 10.0897 13 6.5C13 2.91031 10.0897 0 6.5 0C2.91031 0 0 2.91031 0 6.5ZM6.64531 3.645C6.69194 3.69125 6.729 3.74622 6.75437 3.80679C6.77975 3.86736 6.79295 3.93234 6.79321 3.99801C6.79347 4.06368 6.78079 4.12876 6.7559 4.18953C6.731 4.2503 6.69438 4.30557 6.64812 4.35219L5.01312 6H9.1875C9.32011 6 9.44729 6.05268 9.54105 6.14645C9.63482 6.24021 9.6875 6.36739 9.6875 6.5C9.6875 6.63261 9.63482 6.75979 9.54105 6.85355C9.44729 6.94732 9.32011 7 9.1875 7H5.01312L6.64812 8.64781C6.69437 8.69447 6.73098 8.74979 6.75585 8.81059C6.78073 8.8714 6.79338 8.93651 6.79309 9.00221C6.7928 9.06791 6.77957 9.13291 6.75416 9.19349C6.72875 9.25408 6.69166 9.30906 6.645 9.35531C6.59834 9.40156 6.54303 9.43817 6.48222 9.46304C6.42141 9.48792 6.3563 9.50057 6.2906 9.50028C6.22491 9.49999 6.15991 9.48676 6.09932 9.46135C6.03874 9.43594 5.98375 9.39885 5.9375 9.35219L3.45656 6.85219C3.36363 6.75853 3.31148 6.63194 3.31148 6.5C3.31148 6.36806 3.36363 6.24147 3.45656 6.14781L5.9375 3.64781C5.98376 3.60109 6.03877 3.56395 6.0994 3.53852C6.16003 3.51309 6.22508 3.49987 6.29082 3.4996C6.35657 3.49934 6.42172 3.51205 6.48255 3.537C6.54338 3.56195 6.59869 3.59865 6.64531 3.645Z"
    fill="black"
  />
</svg>

<div class="chat-wrapper" class:no>
  <div class:no bind:this={container} class="chat-container chat-override">
    {#await promise then payload}
      <div in:addWords>
        <!-- Render each word to make the page the right size
        but make Svelte re-render on reveal to trigger the 
        transition.-->
        {#each payload as word, index (index)}
          {#if index < wordsVisible}
            <span class="word" in:fly={{ y: 20, duration: 1000 }}>
              {word}
            </span>
          {:else}<span class="word word-hidden"> {word} </span>{/if}
        {/each}
      </div>
    {:catch error}
      {error.message}
    {/await}
  </div>
</div>

<style lang="scss">
  :global(.chat-container) {
    letter-spacing: -10px;
    position: absolute;
    right: 0;
    overflow-x: hidden;
    padding-bottom: 16px;
    width: 50%;
    // min-height: 101vh;
    font-family: "Noto Serif", serif;
    font-style: italic;
    font-weight: normal;
    font-size: 96px;
    line-height: 112px;
    text-align: justify;

    color: white;

    mix-blend-mode: exclusion;
    &.no {
      mix-blend-mode: exclusion;
      color: #fff;
    }
  }

  .word {
    padding: 0 10px;
    display: inline-block;
    transform: rotate(0.01deg); //solves weird firefor bug . . .
  }

  .word-hidden {
    opacity: 0;
  }
  .back {
    font-size: 25px;
    width: 50px;
    height: 50px;
    position: fixed;
    top: 75px;
    right: 55%;
    text-decoration: underline;
    cursor: w-resize;
    mix-blend-mode: overlay;
    & path {
      fill: #333;
    }
    &:hover path {
      fill: #000;
    }
    &.no {
      mix-blend-mode: difference;
      & path {
        fill: #fff;
      }
      &:hover path {
        fill: #ddd;
      }
    }
  }

  @media (max-width: 850px) {
    .back {
      top: 10px !important;
      right: 50% !important;
    }
  }
  @media (max-width: 600px) {
    :global(.chat-container) {
      top: 40px;
      left: 10px;
      width: 100vw;
      font-size: 72px;
      line-height: 96px;
    }
    .back {
      position: fixed;
      top: 5px;
      left: 0;
      z-index: 100;
      width: 100vw;
      text-align: center;
      &.no {
        mix-blend-mode: normal;
        & path {
          fill: #666;
        }
        &:hover path {
          fill: #000;
        }
      }
    }
    .chat-wrapper {
      background: url(../bg.jpg) no-repeat center center fixed;
      background-size: cover;
      opacity: 0.9;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      mix-blend-mode: normal;
      overflow: scroll;
      overflow-x: hidden;
    }
    &.no {
      transition-property: -moz-filter, -ms-filter, -o-filter, -webkit-filter,
        filter;
      transition-duration: 1s;
      filter: grayscale(1) contrast(20);
    }
  }
</style>
