<script>
  import { onMount } from "svelte";

  function handleScroll(e) {
    let element = document.getElementsByClassName("reverse")[0];
    let scrollEl = e.currentTarget;
    // console.log(
    //   scrollEl.scrollTop,
    //   element.scrollHeight,
    //   element.scrollHeight - scrollEl.scrollTop
    // );
    element.scrollTop =
      element.scrollHeight - element.clientHeight - scrollEl.scrollTop;
  }

  onMount(() => {
    let element = document.getElementsByClassName("reverse")[0];
    element.scrollTop = element.scrollHeight;
  });
</script>

<style lang="scss">
  .info-container {
    font-family: "Roboto", sans-serif;

    cursor: ns-resize;
    padding-top: 50px;
    color: #222;
    position: fixed;
    left: 0;
    top: 20px;
    left: 200px;
    transform: translate(-50%, 0%);
    font-size: 1rem;
    display: grid;
    height: 15rem;

    grid-template-columns: 200px 200px;

    & :global(a) {
      color: black;
      text-decoration: underline;
      position: relative;
      z-index: 2;
      &:hover {
        color: rgb(194, 44, 105);
      }
    }
  }

  .header {
    position: absolute;
    top: 0;
    text-align: center;
    font-size: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .column {
    overflow-y: scroll;
  }

  .reverse {
    // position: relative;
    // left: 14px;
    padding-left: 14px;
    overflow: hidden;
    color: black;
    border-left: black solid thick;
    //transform: translateX(14px);
  }
  .abs {
    scrollbar-width: none;

    position: absolute;
    margin-top: 50px;

    width: 200px;
    left: -14px;
    padding-right: 214px;
    height: 15.25rem;
    text-align: right;
    overflow-y: scroll;
    // z-index: 1;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  @media (max-width: 600px) {
    .info-container {
      /* This cuts off the sides of the scroller on narrower
      than 400px, but none of the text actually gets
      clipped on a 320px wide device (smallest iPhone)*/
      left: 50vw;
      top: 10px;
    }
  }
</style>

<div class="info-container">
  <div class="header">MAGIC <br /> ONEOHTRIX <br /> POINT NEVER</div>

  <div class="abs" on:scroll={(e) => handleScroll(e)}>
    <slot />
  </div>
  <div class="">
    <div class="content" />
  </div>
  <div class="column reverse">
    <div class="content">
      <slot />
    </div>
  </div>
</div>
