<script>
</script>

<svelte:head>
  <link rel="prefetch" as="media" href="./audio/Magic.mp3" />
  <link rel="prefetch" as="media" href="./audio/Y.mp3" />
  <link rel="prefetch" as="media" href="./audio/N.mp3" />
  {#each [...Array(11)
      .fill()
      .map((x, i) => i)] as i}
    <link rel="prefetch" as="media" href="./audio/Y{i + 1}.mp3" />
    <link rel="prefetch" as="media" href="./audio/N{i + 1}.mp3" />
  {/each}
</svelte:head>
